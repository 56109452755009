.free-trial-badge {
    position: absolute;
    top: 0px;
    left: -10px;
    width: 60px;
    height: auto;
    z-index: 10;
    transform: rotate(-15deg);
  }
  
  .free-trial-badge-mobile {
    position: absolute;
    top: 0px;
    right: -10px;
    width: 60px;
    height: auto;
    z-index: 10;
    transform: rotate(15deg);
  }
  